.chapters-container {
    height: 100vh;
    display: flex;
    width: 100%;
    background-image: url("../../../../assets/images/BGPinkPattern.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    .background-circle {
        position: fixed;
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
            width: 100%;
            &.active {
                animation: spin 25s linear infinite;
            }
        }
    }
    .chatpter-content-container {
        z-index: 2;
        opacity: 0;
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        justify-content: center;
        &.loaded {
            opacity: 1;
            animation: fadein 2s ease-in-out;
        }
        > p {
            margin: 0;
            font-family: Yipes;
            text-align: center;
            color: white;
        }
        .prefix {
            font-size: 24px;
            margin-bottom: 30px;
            padding-top: 80px;
        }
        .title {
            font-size: 50px;
        }
        .chapters {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 80px;
            .chapter {
                position: relative;
                height: 500px;
                width: 250px;
                border-radius: 150px;
                border: 2px solid white;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin: 0 20px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                &::before {
                    content: "";
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    bottom: -15px;
                    background-image: url("../../../../assets/images/star-core.svg");
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    z-index: 2;
                }
                &::after {
                    transition: .8s ease-in-out;
                    content: "";
                    position: absolute;
                    height: 520px;
                    width: 270px;
                    border-radius: 160px;
                    border: 1px solid transparent;
                    background: transparent;
                    top: -11px;
                    left: -11px;
                }
                &:hover {
                    &::after {
                        border-color: rgba(255, 255, 255, 0.50);
                    }
                    .overlay {
                        background-position: center bottom;
                    }
                }
                .number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    height: 60px;
                    width: 60px;
                    border-radius: 100%;
                    background-color: white;
                    top: -30px;
                    z-index: 2;
                    > p {
                        color: #FF7BAC;
                        font-family: Yipes;
                        font-size: 24px;
                        margin: 0;
                        padding-top: 10px;
                    }
                }
                .overlay {
                    position: absolute;
                    height: 500px;
                    width: 250px;
                    border-radius: 150px;
                    background: linear-gradient(rgba(0, 0, 0, 0.90), transparent); 
                    transition: background 1s ease-out;
                    background-size: 1px 180%;
                    background-position: center top;
                    transform: rotate(180deg);
                }
                .chap-title {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    align-items: center;
                    justify-content: center;
                    bottom: 80px;
                    > p {
                        margin: 0;
                        color: white;
                        &:first-child {
                            font-size: 32px;
                            font-family: MitogenSignature;
                        }
                        &:last-child {
                            font-size: 40px;
                            font-family: MitogenDisplay;
                        }
                    }
                }
            }
        }
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .chapters-container {
        width: 100vw;
        height: auto;
        .background-circle {
            width: 110%;
        }
        .chatpter-content-container {
            height: 100vh;
            .chapters {
                flex-wrap: wrap;
                width: 100vw;
                .chapter {
                    margin-bottom: 60px;
                    height: 150px;
                    background-position: 78% 28%;
                    background-size: 115%;
                    &::after {
                        height: 170px;
                    }
                    &::before {
                        width: 30px;
                        height: 30px;
                        bottom: -16px;
                    }
                    .overlay {
                        position: absolute;
                        height: 150px;
                        border-radius: 150px;
                    }
                    .chap-title {
                        top: 70px;
                        right: 50px;
                        > p {
                            margin: 0;
                            color: white;
                            &:first-child {
                                font-size: 24px;
                            }
                            &:last-child {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .chapters-container {
        width: 100vw;
        height: auto;
        .background-circle {
            width: 135%;
        }
        .chatpter-content-container {
            height: 100vh;
            .chapters {
                flex-wrap: wrap;
                width: 100vw;
                .chapter {
                    margin-bottom: 60px;
                    height: 150px;
                    background-position: 78% 28%;
                    background-size: 115%;
                    &::after {
                        height: 170px;
                    }
                    &::before {
                        width: 30px;
                        height: 30px;
                        bottom: -16px;
                    }
                    .overlay {
                        position: absolute;
                        height: 150px;
                        border-radius: 150px;
                    }
                    .chap-title {
                        top: 70px;
                        right: 50px;
                        > p {
                            margin: 0;
                            color: white;
                            &:first-child {
                                font-size: 24px;
                            }
                            &:last-child {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: 480px) {
    .chapters-container {
        width: 100vw;
        height: auto;
        .background-circle {
            width: 300%;
        }
        .chatpter-content-container {
            .prefix {
                padding-top: 50px;
            }
            .chapters {
                flex-wrap: wrap;
                width: 100vw;
                .chapter {
                    margin-bottom: 60px;
                    height: 150px;
                    width: 100%;
                    background-position: 78% 28%;
                    background-size: 115%;
                    &::after {
                        height: 170px;
                        width: 105%;
                    }
                    &::before {
                        width: 30px;
                        height: 30px;
                        bottom: -16px;
                    }
                    .overlay {
                        position: absolute;
                        height: 150px;
                        width: 100%;
                        border-radius: 150px;
                    }
                    .chap-title {
                        top: 70px;
                        right: 50px;
                        > p {
                            margin: 0;
                            color: white;
                            &:first-child {
                                font-size: 24px;
                            }
                            &:last-child {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}