.landing-page-container {
    background: linear-gradient(to right, #fe5898, #fe8fbd, #fe75ac, #fe86ad);
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-size: 400% 400%;
    animation: gradient 25s ease infinite;
    box-shadow: 0px 0px 2px #00000026;
    z-index: 5;
    &.exit {
        top: -150%;
        animation: scrollUp 3s ease-in-out;
    }
    .flowers {
        position: absolute;
        margin: 0 auto;
        left: 0; 
        right: 0; 
        top: -12%;
        width: 100%;
        max-width: 1040px;
    }
    .line {
        position: absolute;
        margin: 0 auto;
        left: 0; 
        right: 0; 
        bottom: 0;
    }
    .title {
        width: 100%;
        max-width: 550px;
    }
    .logo {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 120px;
        left: 120px;
        height: 120px;
        width: 120px;
        background-color: white;
        border-radius: 100px;
        box-shadow: 0px 4px 20px rgba(255, 62, 143, 0.75);
    }
    .content-container {
        position: absolute;
        height: 100vh;
        display: flex;
        justify-content: center;
        width: 100%;
        top: 0;
        align-items: center;
        flex-direction: column;
        > button {
            border: 1px solid #FFFFFF;
            background: #83003226;
            border: 1px solid #FFFFFF;
            border-radius: 50px;
            width: 370px;
            height: 100px;
            font-size: 28px;
            cursor: pointer;
            font-family: Yipes;
            color: white;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 70px;
            transition: .3s ease-in-out;
            &:hover {
                background: #8300325b;
            }
            &::after {
                content: "";
                position: absolute;
                width: 15px;
                height: 15px;
                background-image: url('../../../../assets/images/star-core.svg');
                background-repeat: no-repeat;
                background-size: contain;
                bottom: -8px;
            }
        }
    }
    .sky {
        animation: fadein 2s;
        width: 100vw;
    }
    .circle {
        position: absolute;
        opacity: 0;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        background-image: url('../../../../assets/images/star-glow.png');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

    }

    .blink_1 {
        animation-name: blink10;
    }

    .blink_2 {
        animation-name: blink20;
    }

    .blink_3 {
        animation-name: blink30;
    }

    .blink_4 {
        animation-name: blink40;
    }

    .blink_5 {
        animation-name: blink50;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .landing-page-container {
        .flowers {
            width: 100%;
            max-width: 840px;
        }
        .title {
            max-width: 472px;
        }
        .content-container {
            > button {
                width: 300px;
                height: 80px;
                font-size: 24px;
            }
        }
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .landing-page-container {
        .flowers {
            width: 100%;
            max-width: 840px;
        }
        .title {
            max-width: 472px;
        }
        .content-container {
            > button {
                width: 300px;
                height: 80px;
                font-size: 24px;
            }
        }
    }
}

@media all and (max-width: 480px) {
    .landing-page-container {
        .logo {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            left: 0; 
            right: 0;
            top: 80px;
            height: 120px;
            width: 120px;
            background-color: white;
            border-radius: 100px;
            box-shadow: 0px 4px 20px rgba(255, 62, 143, 0.75);
        }
        .flowers {
            width: 100%;
            max-width: 840px;
            display: none;
        }
        .title {
            max-width: 272px;
        }
        .content-container {
            > button {
                width: 250px;
                height: 60px;
                font-size: 18px;
            }
        }
    }
}