.login-container {
    background: linear-gradient(to bottom, #74032F, #be225e, #CF2B6B);
    position: fixed;
    height: 100%;
    width: 100vw;
    overflow: hidden;
    opacity: 1;
    transition: 2s ease-in-out;
    transition-delay: 17.5s;
    &.fadeout {
        visibility: hidden;
        opacity: 0;
    }
    .background-circle {
        position: absolute;
        height: 100vh;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        > img {
            width: 80%;
            &.active {
                animation: spin 25s linear infinite;
            }
        }
    }
    .logo-container {
        position: absolute;
        height: 100vh;
        display: flex;
        justify-content: center;
        width: 100%;
        top: 0;
        align-items: center;
        > svg {
            height: 500px;
            width: 500px;
            object-fit: contain;
        }
        &.fadein {
            animation: fadein 2s;
        }
    }
    .center-container {
        position: absolute;
        height: 100vh;
        display: flex;
        justify-content: center;
        width: 100%;
        top: 0;
        align-items: center;
        visibility: visible;
        opacity: 1;
        transition: 2s ease-in-out;
        transition-delay: 10s;
        &.fadeout {
            visibility: hidden;
            opacity: 0;
        }
        input {
            position: absolute;
            top: -20%;
            opacity: 0;
        }
        .password-container {
            position: relative;
            p {
                font-size: 200px;
                align-items: center;
                margin: 0;
                color: white;
                font-family: Yipes;
            }
        }
    }
    .sky {
        width: 100vw;
        animation: fadein 2s;
    }
    .circle {
        position: absolute;
        opacity: 0;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        background-image: url('../../assets/images/star-glow.png');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

    }

    .blink_1 {
        animation-name: blink10;
    }

    .blink_2 {
        animation-name: blink20;
    }

    .blink_3 {
        animation-name: blink30;
    }

    .blink_4 {
        animation-name: blink40;
    }

    .blink_5 {
        animation-name: blink50;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .login-container {
        .background-circle {
            > img {
                width: 110%;
            }
        }
        
    }
}
@media all and (min-width: 480px) and (max-width: 768px) {
    .login-container {
        .background-circle {
            > img {
                width: 150%;
            }
        }
        .center-container .password-container > p {
            font-size: 150px;
        }
        .logo-container {
            > svg {
                height: 400px;
                width: 400px;
            }
        }
    }
}
@media all and (max-width: 480px) {
    .login-container {
        .background-circle {
            > img {
                width: 180%;
            }
        }
        .center-container .password-container > p {
            font-size: 100px;
        }
        .logo-container {
            > svg {
                height: 250px;
                width: 250px;
            }
        }
    }
}