.start-chapter {
    background: linear-gradient(to right, #fe5898, #fe8fbd, #fe75ac, #fe86ad);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 5;
    visibility: visible;
    opacity: 1;
    animation: fadein 2s ease-in-out;
    transition: 2s ease-in-out;

    &.fadeout {
        opacity: 0;
        visibility: hidden;
    }

    >button {
        background: #83003226;
        border: 1px solid #FFFFFF;
        border-radius: 50px;
        width: 370px;
        height: 100px;
        font-size: 28px;
        cursor: pointer;
        font-family: Yipes;
        color: white;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s ease-in-out;

        &:hover {
            background: #8300325b;
        }

        &::after {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            background-image: url('../../assets/images/star-core.svg');
            background-repeat: no-repeat;
            background-size: contain;
            bottom: -8px;
        }
    }

    .sky {
        animation: fadein 2s;
        width: 100vw;
        position: absolute;
        top: 0;
    }

    .circle {
        position: absolute;
        opacity: 0;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        background-image: url('../../assets/images/star-glow.png');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .blink_1 {
        animation-name: blink10;
    }

    .blink_2 {
        animation-name: blink20;
    }

    .blink_3 {
        animation-name: blink30;
    }

    .blink_4 {
        animation-name: blink40;
    }

    .blink_5 {
        animation-name: blink50;
    }
}

.entry-container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    // overflow-y: hidden;
    flex-wrap: wrap;
    transition: 1s all;

    .logo {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 30px;
        height: 120px;
        width: 120px;
        background-color: white;
        border-radius: 100px;
        z-index: 3;
        box-shadow: 0px 4px 20px rgba(255, 62, 142, 0.596);
        cursor: pointer;
    }

    .entry-info {
        display: flex;
        flex: 1;
        background-color: #FC458B;
        color: white;
        flex-direction: column;
        position: relative;
        padding: 100px 80px;
        height: auto;

        >p {
            margin: 0;
            animation: fadein 4s ease-in-out;
        }

        .chapter-number {
            font-size: 24px;
            font-family: Yipes;
            margin-bottom: 20px;
        }

        .chapter-title {
            font-family: MitogenDisplay;
            font-size: 80px;
            margin-bottom: 30px;
        }

        .photos {
            display: flex;
            column-gap: 80px;
            align-items: center;
            padding-bottom: 50px;
            animation: fadein 4s ease-in-out;

            >div {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 500px;
                width: 350px;
                background: white;
                padding-top: 15px;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.144);

                .photo {
                    height: 320px;
                    width: 320px;
                    background: rgb(29, 29, 29);
                    object-fit: contain;
                }

                .photo-text {
                    padding-top: 30px;
                    font-family: MitogenSignature;
                    font-size: 40px;
                    color: #FF7BAC;
                    font-weight: 700;
                    margin: 0;
                    rotate: -20deg;
                    max-width: 250px;
                }
            }
        }

        .message {
            font-size: 40px;
            font-family: Yipes;
            color: white;
            line-height: 52px;
            animation: fadein 4s ease-in-out;
        }

        .flower {
            position: absolute;
            margin: 0 auto;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .entry-letter {
        display: flex;
        flex: 1;
        background-color: #8A0D3E;
        height: auto;
        overflow-y: auto;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        ::-webkit-scrollbar {
            width: 5px;
            opacity: 0;
        }
        &:hover {
            ::-webkit-scrollbar {
                opacity: 1;
            }
            /* Track */
            ::-webkit-scrollbar-track {
                border-radius: 10px;
            }
            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: #ff7bab8c; 
                border-radius: 10px;
            }
            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: #ff7bab8c; 
            }
        }

        >div:first-child {
            overflow-y: auto;
            height: 75vh;
            margin-top: 140px;
            .line {
                width: 100%;
                height: 1px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 115px;

                &::after {
                    content: "";
                    position: absolute;
                    height: 32px;
                    width: 32px;
                    margin-bottom: 1px;
                    background-image: url('../../assets/images/star-core.svg');
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }

            .letter {
                margin: 0;
                padding: 0 80px;
                font-family: Arboria;
                color: rgba(255, 255, 255, 0.7);
                letter-spacing: 0.48px;
                font-size: 24px;
                font-weight: 700;
                line-height: 40px;
                padding-bottom: 40px;
                animation: fadein 4s ease-in-out;
                > p { margin: 0; }
            }
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 50px 80px;
            animation: fadein 4s ease-in-out;
            column-gap: 20px;

            >button {
                width: 250px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF1F;
                border: 1px solid #FFFFFF;
                border-radius: 50px;
                color: white;
                font-family: Arboria;
                font-size: 18px;
                font-weight: 700;
                cursor: pointer;
                transition: .2s ease-in-out;

                &:hover {
                    background: #ffffff44;
                }
            }
            .hearts-svg {
                position: absolute;
                right: 40px;
                height: 150px;
                bottom: 100px;
                cursor: pointer;
                transition: .2s ease-in-out;
                &:hover {
                    opacity: .75;
                }
                .hearts-day {
                    fill: #ff7bac;
                    font-size: 76px;
                    font-family: MitogenSignature;
                }
    
                .hearts-day,
                .heart-shape {
                    stroke: #ff7bac;
                }
    
                .heart-shape {
                    fill: none;
                    stroke-linecap: round;
                    stroke-width: 3px;
                }
            }
        }
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .entry-container {
        .entry-info {
            height: auto;
            padding-top: 60px;
            padding-bottom: 120px;

            .photos {
                flex-wrap: wrap;

                >div {
                    margin-bottom: 30px;
                }
            }
        }

        .entry-letter {
            max-height: none;

            >div:first-child {
                padding-top: 60px;
                height: 150vh;
 
            }
            .buttons {
                flex-direction: column;
                row-gap: 30px;
                .hearts-svg {
                    bottom: 130px;
                    right: 40px;
                    > svg {
                    width: 180px;
                    }
                }
            }
        }
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .entry-container {
        flex-direction: column;

        .entry-info {
            height: auto;
            padding-bottom: 200px;

            .chapter-number {
                font-size: 21px;
            }

            .chapter-title {
                font-size: 70px;
            }

            .photos {
                flex-wrap: wrap;

                >div {
                    width: 100%;
                    margin-bottom: 30px;

                    .photo {
                        width: 95%;
                    }
                }
            }
            .message {
                text-align: center;
            }
        }

        .entry-letter {
            height: auto;
            overflow-y: visible;
            max-height: none;

            >div:first-child {
                padding-top: 80px;
                height: auto;
            }
            .buttons {
                flex-direction: row;
                .hearts-svg {
                    bottom: 20px;
                    right: 50px;
                    > svg {
                    width: 180px;
                    }
                }

                >button {
                    width: 250px;
                    height: 60px;
                    font-size: 16px;
                }
            }
        }
    }
}

@media all and (max-width: 480px) {
    .start-chapter {
        >button {
            width: 300px;
            height: 80px;
            font-size: 24px;
        }
    }

    .entry-container {
        flex-direction: column;
        width: 100%;

        .entry-info {
            height: auto;
            padding: 0 40px;
            padding-bottom: 200px;

            .chapter-number {
                font-size: 18px;
                padding-top: 50px;
            }

            .chapter-title {
                font-size: 60px;
            }
            .photos {
                flex-wrap: wrap;

                >div {
                    width: 100%;
                    margin-bottom: 30px;

                    .photo {
                        width: 95%;
                    }
                }
            }
            .message {
                text-align: center;
            }
        }

        .entry-letter {
            height: auto;
            overflow-y: visible;
            max-height: none;

            >div:first-child {
                padding: 0px;
                height: auto;
                .letter {
                    padding: 0 40px;
                }
            }

            .buttons {
                padding: 50px 20px;

                .hearts-svg {
                    bottom: 20px;
                    right: 20px;
                    > svg {
                    width: 180px;
                    }
                }

                >button {
                    width: 150px;
                    height: 40px;
                    font-size: 16px;
                }
            }
        }
    }
}